<template>
  <div class="s-item">
    <div class="s-item-info">
      <div class="s-space-y-2 s-mr-auto">
        <template v-if="fields.length > 0">
          <template v-for="(field, i) in fields">
            <p class="s-text-gray s-text-xs s-whitespace-nowrap" v-bind:key="i">
                {{ field.value }}{{ getFieldValue(field) }}
            </p>
          </template>
        </template>
        <template v-else>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">預約時間：{{ bookingDateText }}</p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">預約館別：{{ booking.location_name }}</p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">醫師：{{ booking.consultant_name }}</p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">項目：{{ booking.service }}</p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">預約狀態：{{ booking.status }}</p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">諮詢師：{{ booking.operator_name }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    fieldSetting: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    getFieldValue (field) {
      if (field.key == 'booking_date') {
        return this.bookingDateText
      } else {
        return this.booking[field.key]
      }
    },
  },
  computed: {
    fields () {
      let listSetting =  this.fieldSetting?.list || [];
      if (listSetting) {
        return listSetting.filter(field => field.is_show)
          .sort((a, b) => a.order - b.order)
      } else {
        return []
      }
    },
    moment () {
      return moment
    },
    bookingDateText () {
      let startDate = moment(this.booking.start_at).format('YYYY-MM-DD')
      let startTime = moment(this.booking.start_at).format('HH:mm')
      let endDate = moment(this.booking.end_at).format('YYYY-MM-DD')
      let endTime = moment(this.booking.end_at).format('HH:mm')
      if (startDate == endDate) {
        return `${startDate} ${startTime} ~ ${endTime}`
      } else {
        return `${startDate} ${startTime} ~ ${endDate} ${endTime}`
      }
    },
  },
}
</script>

<style scoped>
.s-item {
  display: flex;
  align-items: center;
  background-color: var(--s-white);
}

.s-item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 1rem;

  box-shadow: rgb(from var(--s-primary) r g b / .5)  0px 2px 8px 0px;
  border-radius: 5px;
}
</style>
